import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css';
import moment from 'moment'
import mitt from 'mitt';

export const emitter = mitt();

const app = createApp(App)

app.config.globalProperties.$moment = moment
app.config.globalProperties.emitter = emitter;



app.use(store).use(router).mount('#app')
