import { createStore } from "vuex";
import session from './session';
import admin from './admin';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    session,
    admin,
  },
});
