
import { defineComponent } from "vue";
import { LockClosedIcon } from '@heroicons/vue/solid'

export default defineComponent({
  name: "Login",
  components: {
    LockClosedIcon
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    async login(e: any) {
      e.preventDefault();
      const data = {
        email: this.email,
        password: this.password,
      };
      await this.$store.dispatch('session/LoginAdmin', data);
      this.gotoDashboard();
    },
    gotoDashboard() {
      window.location.replace('/dashboard/');
    },
    // mounted() {
    //   if (this.$store.state.admin.id) {
    //     this.gotoDashboard();
    //   }
    // }
  },
});
