import API from '@contrarian/ui-shared/lib/API';
import IAdminFetch from '@contrarian/interfaces/IAdminFetch';

export default {
  namespaced: true,
  state: {
    id: null,
    marketingName: null,
    legalName: null,
    firmSlug: null,
    logoUrl: null,
    username: null,
    adminEvents: [],
  },
  mutations: {
    admin(state: any, admin: IAdminFetch) {
      Object.assign(state, admin);
    },
    adminEvents(state: any, events: any) {
      state.adminEvents = events
    },
    erase(state: any) {
      Object.keys(state).forEach(k => (state[k] = null));
    },
  },
  actions: {
    async Fetch({ commit, rootState }: any) {
      try {
        const adminId = rootState.session.adminId;
        const admin = await API.get(`/admins/${adminId}`);
        commit('admin', admin);
        return admin;
      } catch (err) {
        if (err.type === API.INVALID_TOKEN) {
          commit('erase');
          location.href = '/';
        }
        throw err;
      }
    },
  },
};
