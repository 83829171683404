
import * as Vue from "vue";
import Alerts from "@/components/Alerts.vue";
import { useStream } from "./lib/Stream";

export default Vue.defineComponent({
    components: {
        Alerts,
    },
    setup() {
        const stream = useStream()

        return {
            stream
        }
    }
});
