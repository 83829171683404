import { useStore, Store } from 'vuex';
import { io, Socket } from "socket.io-client";
import { API_DOMAIN } from "@contrarian/ui-shared/lib/API";

let socket: Socket;
const WS_PROTOCOL = process.env.VUE_APP_WS_PROTOCOL;


export function useStream() {
  const store = useStore();
  const sessionToken = store.state.session.sessionToken;

  if (!socket) {
    socket = io(`${WS_PROTOCOL}://${API_DOMAIN}?sessionToken=${sessionToken || ''}`, {
      path: '/live',
      // withCredentials: true,
    });

    // client-side
    socket.on("connect", () => {
    });

    socket.on("disconnect", () => {
    });

    socket.on('AdminEvent', ({inviteId, text}) => {
      let state = store.state.admin.adminEvents
      state.push({inviteId, text})
      store.state.admin.adminEvents = state
    });
  }

  return new Stream(store);
}

const target = new EventTarget();

export class Stream {
  private store: Store<any>;

  constructor(store: Store<any>) {
    this.store = store;
  }

  public createInviteEvent(inviteId: string, text: string) {
    socket.emit(`invite-${inviteId}`, text);
  }

  public addApprovedAmount(inviteId: string, approvedAmount: string) {

    socket.emit(`AddApprovedAmount`, { inviteId, approvedAmount });
  }

}

