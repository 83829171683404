import API from '@contrarian/ui-shared/lib/API';
import { explodeValidSessionToken } from '@contrarian/ui-shared/lib/Utils';
import Cookies from '@contrarian/ui-shared/lib/Cookies';

const APP_ID = process.env.VUE_APP_ID;
const sessionToken = Cookies.get(`${APP_ID}.sessionToken`);
const payload = explodeValidSessionToken(sessionToken);

const hasFetched: any = {};

export default {
  namespaced: true,
  state: {
    id: payload ? payload.sessionId : null,
    adminId: payload ? payload.adminId : null,
    memberId: payload ? payload.memberId : null,
    sessionToken: sessionToken,
  },
  mutations: {
    sessionToken(state: any, value: string) {
      Cookies.set(`${APP_ID}.sessionToken`, value);
      const { sessionId, adminId, memberId } = explodeValidSessionToken(sessionToken) || {};
      Object.assign(state, { sessionId, adminId, memberId }, { sessionToken: value });
    },
    erase(state: any) {
      Object.keys(state).forEach(k => (state[k] = null));
      Cookies.remove(`${APP_ID}.sessionToken`);
    },
  },
  actions: {
    async LoginAdmin({ commit }: any, params: { email: string; password: string }) {
      console.log('LoginAdmin: ', params);
      const data = await API.post('/authLogin', Object.assign({ type: 'admin' }, params));
      commit('sessionToken', data.sessionToken);
      return data;
    },

    async LoginMember({ commit }: any, params: { email: string; password: string }) {
      const data = await API.post('/authLogin', Object.assign({ type: 'member' }, params));
      commit('sessionToken', data.sessionToken);
      return data;
    },

    async Logout({ state, commit }: any) {
      const data = await API.post('/authLogout', { sessionToken: state.sessionToken });
      commit('erase');
      return data;
    },
  },
};
