import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: Login,
  }, {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/trading-engine",
    name: "TradingEngine",
    component: () => import("../views/TradingEngine.vue"),
  }, {
    path: "/members",
    name: "AllMembers",
    component: () => import("../views/AllMembers.vue"),
  }, {
    path: "/member/:memberId",
    name: "Member",
    component: () => import("../views/Member.vue"),
  }, {
    path: "/shipments",
    name: "AllShipments",
    component: () => import("../views/ShipmentsAll.vue"),
  }
  , {
    path: "/all-shipment-dates",
    name: "AllShipmentDates",
    component: () => import("../views/ShipmentDates.vue"),
  }
  , {
    path: "/shipments/date/:date",
    name: "ShipmentsByDate",
    component: () => import("../views/ShipmentsByDate.vue"),
  }
  , {
    path: "/shipment/:shipmentId",
    name: "Shipment",
    component: () => import("../views/Shipment.vue"),
  }, {
    path: "/withdraws",
    name: "AllWithdraws",
    component: () => import("../views/AllWithdraws.vue"),
  }
  , {
    path: "/withdraw/:id",
    name: "Withdraw",
    component: () => import("../views/Withdraw.vue"),
  }, {
    path: "/withdraws/create",
    name: "CreateWithdraw",
    component: () => import("../views/CreateWithdraw.vue"),
  }, {
    path: "/deposits",
    name: "AllDeposits",
    component: () => import("../views/AllDeposits.vue"),
  }
  , {
    path: "/deposit/:id",
    name: "Deposit",
    component: () => import("../views/Deposit.vue"),
  }, {
    path: "/invite-cohorts",
    name: "InviteCohorts",
    component: () => import("../views/InviteCohorts.vue"),
  },
  {
    path: "/invite-cohort/:cohortKey",
    name: "InviteCohort",
    component: () => import("../views/InviteCohort.vue"),
  },
  {
    path: "/createInvites",
    name: "CreateInvites",
    component: () => import("../views/CreateInvites.vue"),
  },
  {
    path: "/createCohort",
    name: "CreateCohort",
    component: () => import("../views/CreateCohort.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
